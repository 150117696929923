// .u_attorney-bio
class UAttorneyBio extends Uneet {
  constructor (domElement) {
    super(domElement)
    // this is also set on the SASS file for this uneet (in px)
    this.__colWidth = 365.5
    this.__init()
  }

  __init () {
    this.currentPage = 0
    this.__columnizeContent()
    this.__bindMoreButtons()
  }

  __columnizeContent (n) {
    // helper: create column
    // returns a div.column element
    const createColumn = (n) => {
      const col = document.createElement('div')
      col.addClass('column')
      col.addClass('col-' + n)
      return col
    }
    // helper: create button
    // creates a div.more-btn
    const createButton = (hasPrev = true, hasNext = true) => {
      const btn = document.createElement('div')
      btn.addClass('more-btn')
      const prevLabel = (hasPrev && !hasNext) ? 'MORE' : ''
      const prevBtnStr = (hasPrev) ? '<button class=\'prev\'>' + prevLabel + '</button>' : ''
      const nextBtnStr = (hasNext) ? '<button class=\'next\'>MORE</button>' : ''
      btn.innerHTML = prevBtnStr + nextBtnStr
      return btn
    }

    // bios will always have 2 cols at first, one for the image, the rest for the content
    // the content is then split into several other columns at each <hr>
    // the columns are invisible on mobile via CSS
    let colCount = 0
    // get the base content
    const baseContent = this._e.querySelectorAll('.base-content')[0]
    // split into columns at each <hr>
    let cols = []
    let currentCol = createColumn(cols.length + 1)
    cols.push(currentCol)
    document.querySelectorAll('.base-content > *').forEach((elem) => {
      elem.remove()
      // check if it is an <hr>
      if (elem.localName === 'hr') {
        // if it is an hr then split to a new column
        currentCol = createColumn(cols.length + 1)
        cols.push(currentCol)
      } else {
        // if not an <hr> the just add to the current col
        currentCol.appendChild(elem)
      }
    })
    // append all columns to the columns container
    const columnContainer = this._e.querySelectorAll('div.columns')[0]
    cols.forEach((col, i, cols) => {
      // if there's more than 2 cols
      if (cols.length > 2) {
        // if this is the second col
        if ((i === 1)) {
          // add a more button that only has a next arrow
          col.appendChild(createButton(false, true))
        } else {
          // if this is the last column
          if (i === cols.length - 1) {
            // add a more button that only has a prev arrow
            col.appendChild(createButton(true, false))
          } else {
            // if this is an even column, add a regular more btn
            if (((i + 1) % 2 === 0) && (i > 0)) {
              col.appendChild(createButton(true, true))
            }
          }
        }
      }
      // then append the finalized column to the container
      columnContainer.appendChild(col)
    })
    // finally remove the base content
    baseContent.remove()
  }

  __bindMoreButtons () {
    const btns = this._e.querySelectorAll('button.prev, button.next')
    const columnContainer = this._e.querySelectorAll('div.columns')[0]
    btns.forEach((btn) => {
      btn.onClick((ev) => {
        columnContainer.removeClass('isOnPage-' + this.currentPage)
        if ((ev.target).hasClass('prev')) {
          this.currentPage = (this.currentPage <= 0) ? 0 : (this.currentPage - 1)
        } else {
          this.currentPage = (this.currentPage >= 10) ? 10 : (this.currentPage + 1)
        }
        columnContainer.addClass('isOnPage-' + this.currentPage)
        columnContainer.setAttribute('data-current-col', this.currentPage)
      })
    })
  }
}
