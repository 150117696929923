// .u_mobile-nav-btn
class UMobileNavBtn extends Uneet {
  constructor (domElement, clickCb) {
    super(domElement)
    this.clickCb = clickCb
    this.__init()
  }

  __init () {
    this._e.onClick((ev) => {
      this.__onClickHandler(ev)
    })
  }

  __onClickHandler (ev) {
    this.clickCb(ev)
  }
}
