// .u_main-nav
class UMainNav extends Uneet {
  constructor (domElement) {
    super(domElement)
    this.__init()
  }

  __init () {
    // set the default is Open Value
    this.isOpen = false
    this.toggle(this.isOpen)
  }

  toggle (force = null) {
    if (force !== null) {
      this.isOpen = force
    } else {
      this.isOpen = !this.isOpen
    }
    this._e.toggleClass('isOpen', this.isOpen)
  }

  uncover () {
    this._e.addClass('isUncovered')
  }

  skipAnimation () {
    this._e.addClass('isAnimationSkipped')
    this._e.addClass('isUncovered')
  }

}
