// .u_desktop-home-animation
class UDesktopHomeAnimation extends Uneet {
  constructor (domElement) {
    super(domElement)
    // this.__init()
  }

  __init () {
  }

  uncover () {
    this._e.addClass('isUncovered')
  }

  skipAnimation () {
    this._e.addClass('isAnimationSkipped')
    this._e.addClass('isUncovered')
  }
}
