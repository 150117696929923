// .u_main-footer
class UMainFooter extends Uneet {
  constructor (domElement) {
    super(domElement)
    this.__init()
  }

  __init () {
    this.__parseDate()
  }

  __parseDate () {
    // parse the date
    const anchor = this._e.querySelectorAll('a.news-item')
    if (anchor.length > 0) {
      const dateElement = this._e.querySelectorAll('span.news-date')[0]
      const date = new Date(dateElement.innerHTML)
      const month = ((m) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        return months[parseInt(m)]
      })(date.getUTCMonth())
      dateElement.innerHTML = month + '. ' + date.getUTCDate().toString() + ', ' + date.getUTCFullYear().toString()
    }
  }

  uncover () {
    this._e.addClass('isUncovered')
  }

  skipAnimation () {
    this._e.addClass('isAnimationSkipped')
    this._e.addClass('isUncovered')
  }
}
